import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Img from 'gatsby-image';
import ButtonCircle from '../components/buttonCircle/ButtonCircle';

export const query = graphql`
  query Programming {
    file(relativePath: { eq: "programming.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const IndexPage = ({ data }: any) => {
  return (
    <Layout>
      <div className="container pb-5">
        <div className="row mt-5">
          <div className="col-12 col-md-6 d-flex justify-content-center flex-column">
            <h3>Hi, I'm Carlos</h3>
            <h1>I design, code and optimize modern websites</h1>
            <div>
              <ButtonCircle />
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center flex-column img-floating">
            <Img fluid={data.file.childImageSharp.fluid} />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light py-5">
        <div className="container">
          <div className="d-flex justify-content-start mb-4">
            <h1 className="titleEffect">
              {' '}
              <i className="bi bi-file-earmark-person-fill"></i> About me
            </h1>
          </div>
          <h4 className="mb-3">
            I’m a hard working and dedicated professional front-end
            developer/user interface designer with a keen eye for detail, and a
            determination to deliver the very highest quality. I take great
            pride in my work, and I always try to better myself with every
            project I work on.
          </h4>
          <div className="row px-2 mt-5">
            <div className="col-12 col-md-6">
              <h2 className="fw-bold mb-5 underline--magical d-inline-block">
                Work Experience
              </h2>
              <div className="mb-4">
                <h4 className="fw-bold">
                  Century Financial Corporation CEFINCO
                </h4>
                <p>PHP Developer</p>
                <small>June 3th, 2019 - May 30 2020</small>
              </div>

              <div className="mb-4">
                <h4 className="fw-bold">Don Bosco University</h4>
                <p>.NET Developer</p>
                <small>June 1th 2020 - May 30 2021</small>
              </div>

              <div className="mb-4">
                <h4 className="fw-bold">Applaudo Studios Training program</h4>
                <p>React developer</p>
                <small>June 21 2021 - Today</small>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mb-4">
                <h2 className="fw-bold mb-5 underline--magical d-inline-block">
                  Education
                </h2>
                <h4 className="fw-bold">Don Bosco University</h4>
                <p>Computer Science Engineer</p>
                <small>2013 - 2018</small>
              </div>
              <div className="mb-4">
                <h4 className="fw-bold">Don Bosco University</h4>
                <p>
                  Diploma of specialization in computer networks, CCNA1- CCNA4
                  AND CCNA Security
                </p>
                <small>2019</small>
              </div>
            </div>
          </div>
          <div className="row px-2">
            <div className="col-12 col-md-6">
              <h2 className="fw-bold mb-3 underline--magical d-inline-block">
                Skills
              </h2>
              <div className="mb-2">
                <p>Visual Design</p>
                <p>User Interface</p>
                <p>Database Management</p>
                <p>Strong Leadership Skills</p>
                <p>Programming</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
