import { Link } from 'gatsby';
import React from 'react';
import './ButtonCircle.scss';

const ButtonCircle = () => {
  return (
    <Link to="/contact" className="contact-button">
      <span className="circle" aria-hidden="true">
        <span className="icon arrow"></span>
      </span>
      <span className="button-text">Contact me</span>
    </Link>
  );
};

export default ButtonCircle;
